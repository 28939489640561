import debounce from '@utils/debounce';
import Has from '@utils/has';

let PE = {
  s: function (a, b) {
    a.style.pointerEvents = b
  },

  all: function (a) {
    this.s(a, 'all')
  },

  none: function (a) {
    this.s(a, 'none')
  }
}

export class Pointer {
  constructor(selector, app) {
    this.app      = app;
    this.selector = selector;
    this.timer    = debounce(_ => {
      this.isRunning = false;
      this.pointer('none');
    }, 100);
  }

  init() {
    this.isRunning = false;
    this.el = document.querySelectorAll(this.selector);
    this.elL = this.el.length;
  }

  run () {
    if ( !this.isRunning ) {
      this.isRunning = true;
      this.pointer('all');
    }
    this.timer();
  }

  pointer (state) {
    // console.log('set pointerEvents to : ' + state);
    for (let i = 0; i < this.elL; i++) {
      if (Has(PE, state)) {
        PE[state](this.el[i]);
      } else {
        this.el[i].style.pointerEvents = state;
      }
    }
  }

  _onScroll() {
    this.run();
  }
}