import { isMobile, intersectionObserverSupport } from '@utils/environment';
import Attr from '@utils/attributes';
import { reducedMotion, connection } from '@utils/environment';
import { scrollTo } from '@utils/scroll';
import { getCoords } from '@utils/dom';

import { listen } from 'quicklink';

export class Globals {
  constructor(app) {
    this.app = app;
  }

  init() {
    this.initQuicklink();
    this.initReducedMotion();
    window.onresize = this.responsiveEmbeds; // Run on resize too.
  }

  _onPageReady() {
    this.initAncreLink();
    this.initFauxLink();
    // this.initFooterLink();
    this.initCharming();
    this.initAccordion();
    this.initFillEffect();
    this.initPartnerList();
    this.responsiveEmbeds();
  }
 
  // _onPageLoaded() {
  // }


  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  // Only listen to given links
  initQuicklink() {
    if (!intersectionObserverSupport ) {
      return
    }
    listen({
      el: this.app.DOM.quicklink,
      // origins: [
      //   site_url.replace(/(^\w+:|^)\/\//, '')
      // ], // default to : [location.hostname]
      ignores: [
        /\/api\/?/,
        // /\/wp-admin\/?/,
        // /\/wp-login\/?/,
        // uri => uri.includes('.zip'),
        uri => uri.includes('#'),
        (uri, elem) => elem.hasAttribute('noprefetch')
      ]
    });
  }


  // Ancre Link
  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreLink() {
    const linksAncre = Array.from( document.querySelectorAll('a[href^="#"]:not(#cn-accept-cookie):not(#cn-refuse-cookie):not(.scrollTop):not(.tabs__link):not(.screen-reader-text):not(.screen-reader-shortcut), *[data-scrollTo]') );

    const windowHash = window.location.hash;
    const page = document.getElementById('page');

    if (windowHash !== '') {
      const hash_target = document.querySelector( windowHash );
      
      setTimeout(() => {
        if (hash_target) {
          if (!isMobile) page.style.position = 'relative';
          
          setTimeout(() => {
            const coords = getCoords(hash_target);
            debug(coords);
  
            if (!isMobile) page.style.position = 'fixed';
            // scrollTo(hash_target.offset_top, 800);
            scrollTo(coords.top, 800);
          }, 50);
        }
      }, 150);
    }

    // Anchor link
    // Add Event Listener to all links
    linksAncre.forEach( link => this.initAncreClickForElem(link) );
  }
  initAncreClickForElem(el) {
    el.addEventListener('click', (e) => {
      const target = document.querySelector( e.target.getAttribute('href') ? e.target.getAttribute('href') : e.target.dataset.scrollto );
      // debug(e.target);
      debug('go to anchor', target);

      if ( target ) {
        e.preventDefault();

        // target.scrollIntoView({
        //   behavior: 'smooth'
        // });
        // scrollTo(target.offsetTop, 250);

        if (!isMobile) page.style.position = 'relative';
        
        setTimeout(() => {
          const coords = getCoords(target);
          debug(coords);

          if (!isMobile) page.style.position = 'fixed';
          // scrollTo(target.offsetTop, 800);
          scrollTo(coords.top, 800);
        }, 50);
      }

      return false;
    });
  }


  // Faux link
  // Click on the elem redirect to the data-href location
  initFauxLink() {
    let fauxLink = [...document.querySelectorAll('.faux-link')];
    fauxLink.forEach( link => this.initFauxLinkForElem(link) );
  }
  initFauxLinkForElem(el) {
    el.addEventListener('click', (e) => {
      if (e.target.tagName === 'A') {
        return;
      }

      e.preventDefault();

      let href = el.dataset.href;
      if (href === '' || href === '#') return;

      // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
      // and open the image in a new tab
      if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
        return window.open(href, '_blank');
      }

      return window.location = href;
    });
  }
  

  // Footer Link
  // initFooterLink() {
    // let pigeonLink = document.getElementById('site-contact-link--address');
    // let ponyLink = document.getElementById('site-contact-link--pony');


    // pigeonLink.addEventListener('click', () => {

    // });
  // }

  // Charming
  initCharming() {
    let elems_charm = [...document.querySelectorAll('.charming:not(.is-init)')];
    elems_charm.forEach( el => this.initCharmingForElem(el) );
  }
  initCharmingForElem(el) {
    charming(el, {
      // setClassName: function (index, letter) {
      //   return `char-${index} letter-${letter == ' ' ? 'space' : letter}`
      // }
      // for word splitting
      split: function (string) {
        return string.split(/(\s+)/)
      },
      setClassName: function (index, letter) {
        return `word-${index} letter-${letter == ' ' ? 'space' : letter}`
      }
    });
    Attr.addClass(el, 'is-init');
  }


  // Accordion
  initAccordion() {
    const accordions = [...document.querySelectorAll('.accordion')];
    accordions.forEach( el => this.initAccordionForElem(el) );
  }
  initAccordionForElem(el) {
    const accordion_duration = .8;
    const obj = {
      toggs: [...el.querySelectorAll('.accordion-item-header')],
      contents: [...el.querySelectorAll('.accordion-item-content')],
      lastActive: -1,
      anim: []
    }

    const toggle = function(i) {
      // Want to open another item
      if (i !== obj.lastActive) {

        // Close the current open accordion
        if (obj.toggs[obj.lastActive]) {
          Attr.removeClass(obj.toggs[obj.lastActive], 'is-open');
          obj.anim[obj.lastActive].icon.reverse();
          obj.anim[obj.lastActive].content.out.restart();
        }

        // Open the selected one
        Attr.addClass(obj.toggs[i], 'is-open');
        obj.anim[i].icon.play();
        obj.anim[i].content.in.restart();
        obj.lastActive = i;

      }
      // Re-click on the same item
      else {

        if (obj.anim[i].icon.reversed()) {
          obj.anim[i].icon.play();
          obj.anim[i].content.in.restart();
        } else {
          obj.anim[i].icon.reverse();
          obj.anim[i].content.out.restart();
        }

      }
    }

    // Init each accordion items
    obj.toggs.forEach((togg, i) => {
      var icon_plus = togg.querySelector('.accordion-item-icon');
      var content = obj.contents[i];

      obj.anim[i] = {
        icon: gsap.to(icon_plus, {
          rotate: 45,
          duration: accordion_duration,
          ease: "elastic.inOut(1, 0.5)",
          paused: true,
        }),
        content: {
          in: gsap.to(content, {
            height: 'auto',
            duration: accordion_duration,
            ease: "Power2.inOut",
            paused: true,
          }),
          out: gsap.to(content, {
            height: 0,
            duration: accordion_duration,
            ease: "Power2.inOut",
            paused: true,
          }),
        }
      }

      togg.addEventListener("click", () => toggle(i));
    });

    // Close all accordion contents
    gsap.set( '.accordion-item-content' , { height: 0 } );
  }


  // JS Fill Effect on mouse hover
  initFillEffect() {
    // Fill effect on mouse hover
    const fillElems = [...document.querySelectorAll('.js-fill-effect')];
    if (fillElems.length) {
      fillElems.forEach( el => this.initFillEffectForElem(el) );
    }
  }
  initFillEffectForElem(el) {
    if ( isMobile ) {
      return;
    }
    let box = el.getBoundingClientRect();
    let vertical = el.classList.contains('effect-vertical');

    el.addEventListener('mouseenter', (e) => {
      box = el.getBoundingClientRect();
    });
    el.addEventListener('mousemove', (e) => {
      // const x = e.pageX - e.target.offsetLeft;
      // const y = e.pageY - e.target.offsetTop;

      // e.target.style.setProperty('--x', `${ x }%`);
      if (vertical) {
        e.target.style.setProperty('--effect', `${ (e.offsetX / box.height) * 100 }%`);
      } else {
        e.target.style.setProperty('--effect', `${ (e.offsetX / box.width) * 100 }%`);
      }
    });
  }


  // List of partners logo (with marquee effect)
  initPartnerList() {
    [...document.querySelectorAll('.bao-block-partnerlist')].forEach(el => {
      const lines = [...el.querySelectorAll('.bao-block-partnerlist-line')];

      lines.forEach(line => {
        const partnerlist = line.querySelector('.partnerlist');

        const _clone1 = partnerlist.cloneNode(true);
        // const _clone2 = partnerlist.cloneNode(true);
        partnerlist.parentNode.appendChild(_clone1);
        // partnerlist.parentNode.appendChild(_clone2);
      });
    });
  }


  // Reduced Motion
  initReducedMotion() {
    // https://polypane.app/blog/creating-websites-with-prefers-reduced-data/
    const videos = [...document.querySelectorAll('video')];

    // default to save data being on
    const saveData = connection.saveData || true;
    const slowConnection = ['slow-2g', '2g', '3g'].includes(connection.effectiveType);

    // test if there is no preference, then also check if saveData is not on
    const canAutoPlayAndPreload = !reducedMotion || !saveData || !slowConnection;

    // console.log('saveData', saveData);
    // console.log('slowConnection', slowConnection);
    // console.log('canAutoPlayAndPreload', canAutoPlayAndPreload);

    videos.forEach(video => {
      video.setAttribute('autoplay', canAutoPlayAndPreload);
      video.setAttribute('preload', canAutoPlayAndPreload);

      // if (reducedMotion) {
        // video.pause();
      // }
    });
  }

  
  /**
   * File responsive-embeds.js.
   *
   * Make embeds responsive so they don't overflow their container.
   */

  /**
   * Add max-width & max-height to <iframe> elements, depending on their width & height props.
   *
   * @since 1.0.0
   *
   * @return {void}
   */
  responsiveEmbeds() {
    var proportion, parentWidth;

    // Loop iframe elements.
    document.querySelectorAll( 'iframe' ).forEach( function( iframe ) {
      // Only continue if the iframe has a width & height defined.
      if ( iframe.width && iframe.height ) {
        // Calculate the proportion/ratio based on the width & height.
        proportion = parseFloat( iframe.width ) / parseFloat( iframe.height );
        // Get the parent element's width.
        parentWidth = parseFloat( window.getComputedStyle( iframe.parentElement, null ).width.replace( 'px', '' ) );
        // Set the max-width & height.
        iframe.style.maxWidth = '100%';
        iframe.style.maxHeight = Math.round( parentWidth / proportion ).toString() + 'px';
      }
    } );
  }

}

// export default function(app) {

//   initQuicklink(app);
  
//   initAncreLink(app);
//   initFauxLink(app);
//   initCharming(app);
//   initAccordion(app);

//   initFillEffect(app);

//   initReducedMotion(app);

//   responsiveEmbeds(app);
//   window.onresize = responsiveEmbeds; // Run on resize too.

// }


