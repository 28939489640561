import { body, isMobile, isAdmin } from '@utils/environment';
import { translate, transform } from '@utils/transform';
import { lerp, floor, round, map, getRandomFloat } from '@utils/math';
import Attr from '@utils/attributes';
import Is from '@utils/is';

export class SmoothScroll {
  constructor(app) {
    this.app = app;
    this.rAF = null;
    this.items = [];
    this.DOM = {};

    this.scroll = {
      isScrolling: true,
      // target: localStorage.getItem('scroll') || 0,

      // document scroll Y
      docScroll: 0,

      last: 0,
      current: 0,

      speed: 0,
      acc: 0,
      velo: 0,

      // ease: this.env.isMobile ? 0.35 : 0.075,
      // ease: this.env.isMobile ? 0.35 : 0.1,
      // ease: 0.1,
    };

    // here we define which property will change as we scroll the page
    // -> we will be translating on the y-axis
    this.renderedStyles = {
      translationY: {
        // interpolated value
        previous: 0, 
        // current value
        current: 0, 
        // amount to interpolate
        ease: 0.1,
        // current value setter
        // in this case the value of the translation will be the same like the document scroll
        setValue: () => this.scroll.current
      }
    };

    // set the initial values
    this.getScroll();
  }

  init() {
    this.rAF = null;
    this.items = [];

    // Init the intersection Observer via Bound
    this.boundary = Bound({
      threashold: 0.0,
      margins: {
        top: 0,
        bottom: 0,
        // top: App.bounds.window_h / -10,
        // bottom: App.bounds.window_h / -10,
      },
    });

    // Get the scroll position and update the previous variable
    this.scroll.current = this.scroll.last = this.app.scroll.target;
    // console.log('init scroll.current', this.scroll.current);

    if ( !isAdmin ) {

      this.DOM = {
        // the main element
        main: document.querySelector('[data-scroll]'),
        // the scrollable element
        // we translate this element when scrolling (y-axis)
        scrollable: document.querySelector('[data-scroll-content]'),
      };
      
    } else {

      this.DOM = {
        // the main element
        main: document.querySelector('#editor'),
        // the scrollable element
        // we translate this element when scrolling (y-axis)
        scrollable: document.querySelector('.editor-styles-wrapper'),
      };

    }

    // the [data-scroll] element's style needs to be modified
    this.setStyles();

    // set the initial values
    this.update();

    // set the body's height
    this.setHeight();
  }

  addScrollItem(item) {
    if ( !(Attr.hasClass(item, 'scroll-not-mobile') && isMobile) ) {
      this.items.push( new ScrollItem(item, this) );
    }
  }

  update() {
    // sets the initial value (no interpolation) - translate the scroll value
    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();   
    }   
    // translate the scrollable element
    this.layout();
  }

  setStyles() {
    if ( isMobile || isAdmin ) return;
    // the main [data-scroll] needs to "stick" to the screen and not scroll
    // for that we set it to position fixed and overflow hidden 
    this.DOM.main.style.position = 'fixed';
    this.DOM.main.style.width = this.DOM.main.style.height = '100%';
    this.DOM.main.style.top = this.DOM.main.style.left = 0;
    // this.DOM.main.style.top = Util.hasClass(App.el.app, 'admin-bar') ? ( App.bounds.window_w > 782 ? 32 : 46 ) : 0;
    this.DOM.main.style.overflow = 'hidden';
  }

  setHeight() {
    if ( isMobile || isAdmin ) return;
    // set the heigh of the body in order to keep the scrollbar on the page
    body.style.height = `${this.DOM.scrollable.scrollHeight}px`;
    // body.style.height = `${this.DOM.scrollable.offsetHeight}px`;
  }

  getScroll() {
    if ( isAdmin ) {
      if (typeof this.DOM.scrollable !== 'undefined') {
        this.scroll.docScroll = this.DOM.scrollable.scrollTop || 0;
      }
    } else {
      this.scroll.docScroll = window.pageYOffset || document.documentElement.scrollTop;
    }
    return this.scroll.docScroll;
  }

  getScrollDoc() {
    return this.scroll.docScroll;
  }
  getScrollTarget() {
    return this.scroll.last;
  }

  on(requestAnimationFrame = true) {
    this.addEvents();

    // start the render loop
    requestAnimationFrame && this.requestAnimationFrame();
  }

  off(cancelAnimationFrame = true) {
    cancelAnimationFrame && this.cancelAnimationFrame();

    this.removeEvents();
  }

  requestAnimationFrame() {
    this.rAF = requestAnimationFrame(this.render.bind(this));
  }

  cancelAnimationFrame() {
    cancelAnimationFrame(this.rAF);
  }

  render() {
    if (this.scroll.isScrolling) {
      
      // update the current and interpolated values
      for (const key in this.renderedStyles ) {
        this.renderedStyles[key].current = this.renderedStyles[key].setValue();
        this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
        this.renderedStyles[key].previous = round(this.renderedStyles[key].previous, 3);

        if (key === 'translationY') {
          if (
            (this.renderedStyles[key].current > this.renderedStyles[key].previous && this.renderedStyles[key].previous > this.renderedStyles[key].current - 0.01) ||
            (this.renderedStyles[key].current < this.renderedStyles[key].previous && this.renderedStyles[key].previous < this.renderedStyles[key].current + 0.01)
          ) {
            this.renderedStyles[key].previous = this.renderedStyles[key].current;
          }
          else if (this.renderedStyles[key].previous === this.renderedStyles[key].current) {
            this.scroll.isScrolling = false;
          }

          // Get scrolling speed
          // this.scroll.speed = Math.abs(this.scroll.current - this.scroll.last);
          this.scroll.speed = Math.abs(this.renderedStyles[key].current - this.renderedStyles[key].previous);
        }
      }

      // this.scroll.previous = lerp(this.scroll.previous, this.scroll.current, this.scroll.ease);
      // this.scroll.previous = round(this.scroll.previous, 3);
      // if (
      //   (this.scroll.current > this.scroll.previous && this.scroll.previous > this.scroll.current - 0.01) ||
      //   (this.scroll.current < this.scroll.previous && this.scroll.previous < this.scroll.current + 0.01)
      // ) {
      //   this.scroll.previous = this.scroll.current;
      // }
      // else if (this.scroll.previous === this.scroll.target) {
      //   this.scroll.isScrolling = false;
      // }

      // Velocity & acceleration
      this.scroll.acc  = floor(this.scroll.speed / this.app.bounds.window_w, 3);
      this.scroll.velo =+ this.scroll.acc;
      
      // Update last scroll value
      this.scroll.last = this.scroll.current;
      
      // and translate the scrollable element
      this.layout();

      // for every Scroll Item
      for (const item of this.items) {
        // if the item is inside the viewport call it's render function
        // this will update item's styles, based on the document scroll value and the item's position on the viewport
        if ( item.isVisible ) {
          if ( item.insideViewport ) {
            item.render();
          }
          else {
            item.insideViewport = true;
            item.update();
          }
        }
        else {
          item.insideViewport = false;
        }
      }
    }

    // loop..
    this.requestAnimationFrame();
  }

  layout() {
    if ( isMobile || isAdmin ) return;
    // console.log('smooth to ', this.renderedStyles.translationY.previous);
    // apply all styles changes to scrollable element
    // this.DOM.scrollable.style.transform = `translate3d(0,${-1*this.renderedStyles.translationY.previous}px,0)`;
    translate(this.DOM.scrollable, 0, -1*this.renderedStyles.translationY.previous, 'px'); // el, x, y, unity
    // translate(this.DOM.scrollable, 0, -this.app.scroll.last, 'px'); // el, x, y, unity
  }

  // destroy() {
  //   this.app.el.body.style.height = ''

  //   this.data = null

  //   this.removeEvents()
  //   this.cancelAnimationFrame()
  // }

  addEvents() {
    // window.addEventListener('scroll', this._onScroll);
    // on resize reset the body's height
    // window.addEventListener('resize', this.setSize);
  }

  removeEvents() {
    // window.removeEventListener('scroll', this._onScroll);
    // window.removeEventListener('resize', this.setSize);
  }

  _onPageReady() {
    if ( !isAdmin ) {
      // Get all .scroll-item elements an init them
      [...this.DOM.scrollable.querySelectorAll('.scroll-item')].forEach( item => this.addScrollItem(item) );
    }

    this.on();
  }

  _onPageLoaded() {
    this.setHeight();

    setTimeout(() => this.setHeight(), 500);
  }
  
  _onPageOut() {
    this.off();
  }

  // _onUpdate() {
  // }

  _onScroll() {
    this.scroll.isScrolling = true;
    this.getScroll();
    this.scroll.current = this.app.scroll.target;
    // console.log('smooth onScroll', this.scroll.current);

    // const scrollY = window.scrollY || window.pageYOffset
    // this.data.current = scrollY
    // this.cache.forEach((elem) => {
    //   // el.sy = scrollY
    //   elem.isInViewport = Util.isInViewport(elem.el, 0.2)
    // })
  }

  _onResize() {
    // this.getSize();
    this.setHeight();
    // this.scroll();

    // for every Scroll Item
    for (const item of this.items) {
      item.resize();
    }
  }
}

class ScrollItem {
  constructor(el, smoothScroll) {
    this.smooth = smoothScroll;

    // the .item element
    this.DOM = {el: el};

    // here we define which property will change as we scroll the page and the item is inside the viewport
    this.renderedStyles = {}

    // Define renderedStyles depends on class
    if ( Attr.hasClass(this.DOM.el, 'js-parallax') ) {
      // in this case we will be:
      // - translating the item
      this.renderedStyles.translationY = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        fromValue: this.DOM.el.dataset.parallaxFrom ? Number( this.DOM.el.dataset.parallaxFrom ) : Number( getRandomFloat(30,400) ),
        toValue: this.DOM.el.dataset.parallaxTo ? Number( this.DOM.el.dataset.parallaxTo ) : false,
        unity: this.DOM.el.dataset.parallaxUnity ? this.DOM.el.dataset.parallaxUnity : 'px',
        // current value setter
        setValue: () => {
          let fromValue = this.renderedStyles.translationY.fromValue;
          let toValue = this.renderedStyles.translationY.toValue !== false ? this.renderedStyles.translationY.toValue : -1*fromValue;
          
          if (isMobile) {
            fromValue = fromValue / 5;
            toValue = toValue / 5;
          }

          const val = map(this.props.top - this.smooth.scroll.current, app.bounds.window_h, -1 * this.props.height, fromValue, toValue);
          // console.log(this.DOM.el);
          // console.log(fromValue, toValue, val);
          // console.log(this.props.top, this.smooth.scroll.current, app.bounds.window_h, this.props.height);
          return fromValue <= 0 ? Math.min( Math.max(val, fromValue), toValue ) : Math.max( Math.min(val, fromValue), toValue );
        },
      };
    }
    if ( Attr.hasClass(this.DOM.el, 'js-image-scale') ) {
      // in this case we will be:
      // - scaling the inner image
      // this.DOM.image = this.DOM.el.querySelector('img');
      this.renderedStyles.imageScale = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        fromValue: this.DOM.el.dataset.scaleFrom ? parseFloat( this.DOM.el.dataset.scaleFrom ) : 1,
        toValue: this.DOM.el.dataset.scaleTo ? parseFloat( this.DOM.el.dataset.scaleTo ) : 1.5,
        // current value setter
        setValue: () => {
          const fromValue = this.renderedStyles.imageScale.fromValue;
          const toValue = this.renderedStyles.imageScale.toValue;
          const val = map(this.props.top - this.smooth.scroll.current, app.bounds.window_h, -1 * this.props.height, fromValue, toValue);
          return fromValue > toValue ? Math.max( Math.min(val, fromValue), toValue ) : Math.max( Math.min(val, toValue), fromValue );
        },
      };
    }
    if ( Attr.hasClass(this.DOM.el, 'js-spring') ) {
      // in this case we will be:
      // - spring (translate) the item
      this.renderedStyles.spring = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: this.DOM.el.dataset.springEase ? parseFloat(this.DOM.el.dataset.springEase) : 0.1, // amount to interpolate
        // current value setter
        setValue: () => {
          // // const spring = Math.min(this.smooth.scroll.velo, .1) * (this.smooth.scroll.speed * 2.5);
          // // const spring = Math.min(this.smooth.scroll.velo * (this.smooth.scroll.speed * 0.75), 150);

          const toValue = 150;
          const val = this.smooth.scroll.velo * this.smooth.scroll.speed * 0.75;
          return Math.min(val, toValue);
        },
      };
    }
    if ( Attr.hasClass(this.DOM.el, 'js-skew') ) {
      // in this case we will be:
      // - skew (transform) the item
      this.renderedStyles.skew = {
        previous: 0, // interpolated value
        current: 0, // current value
        ease: 0.1, // amount to interpolate
        // current value setter
        setValue: () => {
          // const skew = this.smooth.scroll.velo * 7.5;
          const skew = this.smooth.scroll.velo * this.smooth.scroll.speed * 1.5;
          const toValue = 30;
          const fromValue = 0;
          const val = map(skew, 20, 100, fromValue, toValue);
          const value = Math.max( Math.min( val, toValue ), fromValue );
          return this.smooth.renderedStyles.translationY.previous < this.smooth.scroll.current ? value : -1*value;
        },
      };
    }
    
    // // gets the item's height and top (relative to the document)
    // this.getSize();

    // set the initial values
    this.update();

    // use the IntersectionObserver API to check when the element is inside the viewport
    // only then the element styles will be updated
    this.isVisible = false;
    this.insideViewport = false;

    // this.observer = new IntersectionObserver((entries) => {
    //   entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0);
    // });
    // this.observer.observe(this.DOM.el);

    this.smooth.boundary.watch(this.DOM.el, (ratio) => {
      // console.log('enter', this, ratio);
      this.isVisible = true;
    }, (ratio) => {
      // console.log('leave', this);
      this.isVisible = false;
    });

    // init/bind events
    this.addEvents();
  }

  update() {
    // gets the item's height and top (relative to the document)
    this.getSize();

    // sets the initial value (no interpolation)
    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
    }
    // apply changes/styles
    this.layout();
  }

  getSize() {
    const rect = this.DOM.el.getBoundingClientRect();
    this.props = {
      // item's height
      height: rect.height,
      // offset top relative to the document
      top: this.smooth.scroll.current + rect.top
    };
  }

  addEvents() {
    // window.addEventListener('resize', () => this.resize());
  }

  resize() {
    // gets the item's height and top (relative to the document)
    this.getSize();
    // on resize reset sizes and update styles
    this.update();
  }

  render() {
    // update the current and interpolated values
    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].current = this.renderedStyles[key].setValue();
      this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
      this.renderedStyles[key].previous = round(this.renderedStyles[key].previous, 3);
    }
    
    // and apply changes
    this.layout();
  }
  layout() {
    // Define the transform to apply
    let tr = '';

    if (Is.def(this.renderedStyles.imageScale)) {
      tr += `scale3d(${this.renderedStyles.imageScale.previous},${this.renderedStyles.imageScale.previous},1)`;
    }
    if (Is.def(this.renderedStyles.translationY)) {
      if (tr !== '') tr += ' ';
      tr += `translate3d(0,${this.renderedStyles.translationY.previous}${this.renderedStyles.translationY.unity},0)`;
    }
    else if (Is.def(this.renderedStyles.spring)) {
      if (tr !== '') tr += ' ';
      tr += `translate3d(0,${this.renderedStyles.spring.previous}px,0)`;
    }
    if (Is.def(this.renderedStyles.skew)) {
      if (tr !== '') tr += ' ';
      tr += `skewY(${this.renderedStyles.skew.previous}deg)`;
    }

    transform(this.DOM.el, tr);
  }
}