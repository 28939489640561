import Attr from '@utils/attributes';

export class RevealOnScroll {
  constructor(selector, app) {
    const that    = this;
    this.app      = app;
    this.selector = selector || '.reveal-on-scroll';
    this.classes  = { start: 'is-visible', repeat: 'ros-repeat' };

    Attr.addClass(this.app.DOM.body, 'ros');

    // Create Events
    // https://developer.mozilla.org/fr/docs/Web/Guide/DOM/Events/Creating_and_triggering_events
    this.events = {
      'enter': document.createEvent('Event'),
      'leave': document.createEvent('Event')
    };
    this.events.enter.initEvent('RevealOnScroll:enter', true, true);
    this.events.leave.initEvent('RevealOnScroll:leave', true, true);

    // window.addEventListener('bao_load_more:complete', (e) => {
    //   var newElems = [...document.querySelectorAll(this.selector)];

    //   newElems.forEach( item => {
    //     if ( !that.elems.includes(item) ) {
    //       that.initRevealOnScrollForElem(item);
    //     }
    //   } );

    //   if ( window.rm_lazyload ) {
    //     rm_lazyload.init();
    //   }
    // });
  }

  init() {
    this.bounds = Bound({
      // root: window,
      threshold: 0.2,// 20% of the element should be within the root element (eg: window)
      margins: {
        top: 0,
        // top: -100,
        // top: App.bounds.window_h / -10,// 10% of the window height
        bottom: 0,
        // bottom: -100,
        // bottom: App.bounds.window_h / -10,// 10% of the window height
      },
    });

    this.getElems();
  }

  runForElem(elem) {
    const that = this;
    const repeat = Attr.hasClass(elem, this.classes.repeat);

    this.bounds.watch(elem, (ratio) => {
      // console.log('enter', ratio);
      this.onEnter(elem);
      if (!repeat) {
        this.bounds.unWatch(elem);
      }
    }, (ratio) => {
      // console.log('leave', ratio);
      if (repeat) {
        this.onLeave(elem);
      }
    });
  }

  getElems() {
    this.elems = [...document.querySelectorAll(this.selector)];
  }

  run() {
    this.elems.forEach( el => this.runForElem(el) );
  }

  onEnter (elem) {
    // console.log('is revealed', elem);
    elem.style.visibility = 'visible';
    Attr.addClass(elem, this.classes.start);
    elem.dispatchEvent(this.events.enter);
  }

  onLeave (elem) {
    // console.log('is leaving', elem);
    // elem.style.visibility = 'visible';
    Attr.removeClass(elem, this.classes.start);
    elem.dispatchEvent(this.events.leave);
  }

  _onPageLoaded() {
    this.run();
  }

}