import { lerp } from '@utils/math';
import { getMousePos } from '@utils/mouse';

export class Cursor {
  constructor(selector, app) {
    this.app = app;

    this.DOM = {el: document.querySelector(selector)};
    this.DOM.circle = this.DOM.el.querySelector('.cursor__inner--circle');
    this.DOM.arrows = {
      right: this.DOM.el.querySelector('.cursor__side--right'),
      left: this.DOM.el.querySelector('.cursor__side--left')
    };
    // this.DOM.pony = this.DOM.el.querySelector('.cursor__pony');
    this.DOM.circle.style.opacity = 0;

    this.scale = 1;
    this.lastScale = 1;
    this.bounds = this.DOM.circle.getBoundingClientRect();

    this.renderedStyles = {
      tx: {previous: 0, current: 0, amt: 0.2},
      ty: {previous: 0, current: 0, amt: 0.2},
    };

    // Track the mouse position
    this.mouse = {x: 0, y: 0};

    this.onMouseMoveEv = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
      this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = this.mouse.y - this.bounds.height/2;
      gsap.to(this.DOM.circle, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});
      window.removeEventListener('mousemove', this.onMouseMoveEv);
    };
    this.getMousePos = (ev) => {
      this.mouse = getMousePos(ev);
      this.mouse.y -= app.scroll.target;
    }

    window.addEventListener('mousemove', this.onMouseMoveEv);
    window.addEventListener('mousemove', this.getMousePos);
  }

  init() {
    // Activate the enter/leave/click methods of the custom cursor when hovering in/out on every <a> (and the close content ctrl)
    [...document.querySelectorAll('a'), ...document.querySelectorAll('button')].forEach((link) => {
      link.addEventListener('mouseenter', () => this.enter());
      link.addEventListener('mouseleave', () => this.leave());
      link.addEventListener('click', () => this.click());
    });
    document.addEventListener('click', () => this.click());
  }

  enter() {
    this.scale = 1.9;
  }
  leave() {
    this.scale = 1;
  }
  click() {
    this.lastScale = .4;
  }

  showArrows() {
    gsap.to(Object.values(this.DOM.arrows), {
      ease: 'Expo.easeOut',
      startAt: {x: i => i ? 10 : -10 },
      opacity: 1,
      x: 0,
      duration: 1
    });
  }
  hideArrows() {
    gsap.to(Object.values(this.DOM.arrows), {
      ease: 'Expo.easeOut',
      x: i => i ? 10 : -10,
      opacity: 0,
      duration: 1
    });
  }

  // showPony() {
  //   gsap.to(this.DOM.pony, {
  //     ease: 'Expo.easeOut',
  //     startAt: { scale: 0 },
  //     scale: 1,
  //     duration: 1
  //   });
  // }
  // hidePony() {
  //   gsap.to(this.DOM.pony, {
  //     ease: 'Expo.easeOut',
  //     scale: 0,
  //     duration: 1
  //   });
  // }

  _onUpdate() {
    this.renderedStyles['tx'].current = this.mouse.x - this.bounds.width/2;
    this.renderedStyles['ty'].current = this.mouse.y - this.bounds.height/2;

    for (const key in this.renderedStyles ) {
      this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
    }

    this.lastScale = lerp(this.lastScale, this.scale, 0.15);

    this.DOM.circle.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.lastScale})`;
  }
}
