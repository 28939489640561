import { html, body, isIE, isMobile, isDebug, intersectionObserverSupport, cssMixBlendMode, reducedMotion } from '@utils/environment';
import { getWindowDimensions } from '@utils/window';
import debug from '@utils/debug';
import Attr from '@utils/attributes';
import { addToArray, removeFromArray } from '@utils/array';
// import { lerp, round, floor } from '@utils/math';
import throttle from '@utils/throttle';

import {
  // Example,
  Globals,
  Page,
  PageLoader,
  BackToTop,
  Pointer,
  Cursor,
  Cookie,
  Navigation,
  SmoothScroll,
  RevealOnScroll,
  // Load
} from '@js/modules';

import gsap from 'gsap';
const charming = require('charming');
import Bound from 'bounds.js';
var imagesLoaded = require('imagesloaded');

window.debug = debug;
window.gsap = gsap;
window.charming = charming;
window.Bound = Bound;

// console.log(window.matchMedia('(prefers-reduced-motion: reduce)').matches);
// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
// if (connection.saveData || ['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
//   // prevent loading resources and other data saving measures
// }


let app = {

  DOM: {
    html: html,
    body: body,
    header: document.getElementById('masthead'),
    main: document.getElementById('page'),

    quicklink: body,
  },

  bounds: {
    window_w: 0,
    window_h: 0,
    appOffsetTop: 0
  },

  modules: [],
  pageModules: [],

  scroll: {
    // isScrolling: true,
    last: 0,
    // target: localStorage.getItem('scroll') || 0,
    target: 0,
    // diff: 0,
    // acc: 0,
    // velo: 0,
    // // ease: this.env.isMobile ? 0.35 : 0.075,
    // // ease: this.env.isMobile ? 0.35 : 0.1,
    // ease: 0.1,

    scrollTreshold : 20,
    scrollDirection: false,
  },

  page: {
    last: '',
    current: '',
    to: '',
  },

  init() {
    // this.initScrollPosition = this.scroll.target;

    // Callbacks
    this._resizeCb = (e) => this._onResize(e);
    this._scrollCb = (e) => this._onScroll(e);

    // Vars
    const windowDim = getWindowDimensions();
    this.bounds.window_w = windowDim.width;
    this.bounds.window_h = windowDim.height;
    this.bounds.appOffsetTop = this.DOM.main.offsetTop;
    this.firstInit = true;

    // Classes from environment
    Attr.addClass(html, isIE ? 'is-ie' : 'not-ie');
    Attr.addClass(html, isMobile ? 'is-mobile' : 'not-mobile');
    Attr.addClass(html, cssMixBlendMode ? 'mix-blend-mode' : 'no-mix-blend-mode');
    Attr.addClass(html, intersectionObserverSupport ? 'intersection-observer' : 'no-intersection-observer');
    Attr.addClass(html, reducedMotion ? 'is-reduced-motion' : 'no-reduced-motion');

    // this.scroll.target = this.scroll.last = this.getScrollY();
    this.initServiceWorker();

    // Construct modules
    // this.Example = new Example(this);
    // this.addModule(this.Example);
    this.Globals = new Globals(this);
    this.addModule(this.Globals);
    this.BackToTop = new BackToTop(this);
    this.addModule(this.BackToTop);
    this.Pointer = new Pointer('#_p', this);
    this.addModule(this.Pointer);
    this.Cursor = new Cursor('.cursor', this);
    this.addModule(this.Cursor);
    this.SmoothScroll = new SmoothScroll(this);
    this.addModule(this.SmoothScroll);
    this.ROS = new RevealOnScroll('.reveal-on-scroll', this);
    this.addModule(this.ROS);
    this.PageLoader = new PageLoader(this);
    this.addModule(this.PageLoader);
    // this.Load = new Load(this);
    // this.addModule(this.Load);
    this.Cookie = new Cookie(this);
    this.addModule(this.Cookie);
    this.Navigation = new Navigation(this);
    this.addModule(this.Navigation);

    // Init the page
    this.pageInit();

    // Start RAF
    this._onUpdate();

    // Misc
    this.copyright();
  },

  pageInit() {
    // Page
    // Home page
    if (Attr.hasClass(body, 'home')) {
      this.Page = new Page('Home', this);
    }
    // Single Projet
    else if (Attr.hasClass(body, 'single-projets')) {
      this.Page = new Page('SingleProjets', this);
    }
    // Other
    else {
      this.Page = new Page('Default', this);
    }
    this.addPageModule(this.Page);

    // Preload images
    this.preloadImages().then(() => {
      // Initialize all modules
      this.call('init').then(() => {

        // Page is ready
        this.onPageReady().then(() => {
          this.onPageLoaded();
        });

      });
    });
  },

  // Preload images
  preloadImages() {
    const _this = this;
    const pr = [];

    // Get all data-img="src" elems and preload them (as <img>)
    const imgwrap = document.createElement('div');
    imgwrap.style.visibility = 'hidden';
    body.appendChild(imgwrap);

    [...document.querySelectorAll('.has-data-img')].forEach(el => {
      const imgEl = document.createElement('img');
      imgEl.style.width = 0;
      imgEl.src = el.dataset.img;
      // imgEl.className = 'preload';
      imgwrap.appendChild(imgEl);
    });
    
    // Get all images
    let images = document.querySelectorAll('img');
    let imagesBG = document.querySelectorAll('.has-bg-img');
    this.PageLoader.add(images.length + imagesBG.length);

    pr.push(
      new Promise((resolve, reject) => {
        let il = imagesLoaded(images, () => {
          imgwrap.parentNode.removeChild(imgwrap);
          resolve();
        });

        // let il = imagesLoaded(document.querySelectorAll('img'), resolve);

        // il.on( 'always', function( instance ) {
        //   console.log('ALWAYS - all images have been loaded');
        // });
        il.on( 'done', function( instance ) {
          // console.log('DONE  - all images have been successfully loaded');
        });
        il.on( 'progress', function( instance, image ) {
          var result = image.isLoaded ? 'loaded' : 'broken';
          // console.log( 'image is ' + result + ' for ' + image.img.src );

          _this.PageLoader.increment();
        });
      })
    );
    pr.push(
      new Promise((resolve, reject) => {
        let il = imagesLoaded(document.querySelectorAll('.has-bg-img'), { background: true }, resolve);

        // il.on( 'always', function( instance ) {
        //   console.log('ALWAYS - all background images have been loaded');
        // });
        il.on( 'done', function( instance ) {
          // console.log('DONE  - all background images have been successfully loaded');
        });
        il.on( 'progress', function( instance, image ) {
          var result = image.isLoaded ? 'loaded' : 'broken';
          // console.log( 'background image is ' + result + ' for ' + image.img.src );

          _this.PageLoader.increment();
        });
      })
    );

    return Promise.all(pr);
  },

  _addListeners() {
    window.addEventListener('resize', throttle(this._resizeCb, 200), { passive: true });
    window.addEventListener('scroll', throttle(this._scrollCb, 30));

    // window.addEventListener('beforeunload', () => {
    //   localStorage.setItem('scroll', this.scroll.target);
    // });
  },

  _removeListeners() {
    window.removeEventListener('resize', this._resizeCb, { passive: true });
    window.removeEventListener('scroll', this._resizeCb);
  },

  // check for SW support and register
  initServiceWorker () {
    // if ('serviceWorker' in navigator) {
    //   const t = this
    //   // const scope = '/';
    //   const scope = '/Lab/quatuor-nebula/'

    //   // Delay registration until after the page has loaded, to ensure that our
    //   // precaching requests don't degrade the first visit experience.
    //   // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
    //   window.addEventListener('load', function () {
    //     // Your service-worker.js *must* be located at the top-level directory relative to your site.
    //     // It won't be able to control pages unless it's located at the same level or higher than them.
    //     // See https://github.com/slightlyoff/ServiceWorker/issues/468
    //     // navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
    //     navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
    //       debug('Service Worker registered successfully.')
    //     }).catch(function(e) {
    //       console.error('Error during service worker registration:', e)
    //     })
    //   })



    //   // Detect if site launch via PWA
    //   if (
    //     window.matchMedia('(display-mode: standalone)').matches ||
    //     window.navigator.standalone === true // for Safari
    //   ) {
    //     console.log('display-mode is standalone');
    //   }
    //   // // And same in CSS
    //   // @media all and (display-mode: standalone) {
    //   //   body {
    //   //     background-color: yellow;
    //   //   }
    //   // }

    //   let deferredPrompt;

    //   window.addEventListener('beforeinstallprompt', (e) => {
    //     console.log('test from beforeinstallprompt');

    //     // Stash the event so it can be triggered later.
    //     deferredPrompt = e;
    //     // Update UI notify the user they can add to home screen
    //     showInstallPromotion();
    //     // prompt();
    //   });

    //   window.addEventListener('appinstalled', (evt) => {
    //     console.log('PWA installed');
    //   });
    // }
  },


  //--- Hooks

  onPageReady() {
    if (this.firstInit) {
      this.firstInit = false;
      // console.log('add listener');
      // Listeners
      this._addListeners();
    }

    return this.call('_onPageReady');
  },

  onPageLoaded() {
    return this.call('_onPageLoaded');
  },

  onPageOut() {
    return this.call('_onPageOut');
  },

  onPageIn() {
    return this.call('_onPageIn');
  },

  onPageChange() {
    // Destroy all modules
    return this.call('destroy').then(() => {
      // Remove page specific modules
      this.pageModules.forEach(m => {
        // console.log('try to remove module', m, this.modules);
        removeFromArray(this.modules, m);
      });
      this.pageModules = [];
    });
  },

  _onUpdate () {
    // // Scroll
    // if (this.scroll.isScrolling) {
    //   this.scroll.last = lerp(this.scroll.last, this.scroll.target, this.scroll.ease);
    //   this.scroll.last = round(this.scroll.last, 3);
    //   if (
    //     (this.scroll.target > this.scroll.last && this.scroll.last > this.scroll.target - 0.01) ||
    //     (this.scroll.target < this.scroll.last && this.scroll.last < this.scroll.target + 0.01)
    //   ) {
    //     this.scroll.last = this.scroll.target;
    //   }
    //   else if (this.scroll.last === this.scroll.target) {
    //     this.scroll.isScrolling = false;
    //   }

    //   // Velocity & acceleration
    //   this.scroll.diff   = this.scroll.target - this.scroll.last;
    //   this.scroll.acc    = floor(this.scroll.diff / this.bounds.window_w, 3);
    //   this.scroll.velo   =+ this.scroll.acc;
    // }

    this.call('_onUpdate');

    requestAnimationFrame(this._onUpdate.bind(this));
  },

  _onScroll(e) {
    // console.log(e);
    // this.scroll.isScrolling = true;
    this.scroll.last = this.scroll.target;
    this.scroll.target = this.getScrollY();

    // Scroll Direction
    // if ( this.scroll.target > this.scroll.last + this.scroll.scrollTreshold ) {
    //   this.scroll.scrollDirection = 'down';
    //   Util.removeClass(this.el.body, CLASSES.scrollingUp);
    //   Util.addClass(this.el.body, CLASSES.scrollingDown);
    // } else if ( this.scroll.target < this.scroll.last - this.scroll.scrollTreshold ) {
    //   this.scroll.scrollDirection = 'up'
    //   Util.removeClass(this.el.body, CLASSES.scrollingDown);
    //   Util.addClass(this.el.body, CLASSES.scrollingUp);
    // }

    // Has Scroll ?
    // if ( this.scroll.target > this.bounds.appOffsetTop ) {
    if ( this.scroll.target > this.bounds.window_h / 3 ) {
      // Util.addClass(this.el.body, CLASSES.hasScroll);
      body.classList.add('hasScroll');
    } else {
      // Util.removeClass(this.el.body, CLASSES.hasScroll);
      body.classList.remove('hasScroll');
    }

    this.call('_onScroll');
  },

  _onResize(e) {
    // console.log(e);

    const windowDim = getWindowDimensions();
    this.bounds.window_w = windowDim.width;
    this.bounds.window_h = windowDim.height;
    this.bounds.appOffsetTop = this.DOM.main.offsetTop;

    this.call('_onResize');
  },


  //--- Helpers

  getScrollY () {
    return window.scrollY || document.documentElement.scrollTop;
  },

  addModule(module) {
    addToArray(this.modules, module);
  },

  addPageModule(module) {
    addToArray(this.pageModules, module);
    addToArray(this.modules, module);
  },

  call(func) {
    // console.log('call ' + func);

    if (func === '_onUpdate' || func === '_onResize' || func === '_onScroll') {
      this.modules.forEach(m => {
        if (typeof m[func] === 'function') {
          m[func]();
        }
      });
    } else {
      let pr = [];
      this.modules.forEach(m => {
        if (typeof m[func] === 'function') {
          pr.push(m[func]());
        }
      });
      return Promise.all(pr).then(() => {
        // console.log('all call ' + func + ' ended');
      });
    }
  },

  copyright () {
    const style = [
      'color: #fff',
      'background: #ffc308',
      'padding: 4px 8px',
      'border-left: 5px solid #282828',
      'border-right: 5px solid #282828'
    ].join(';')
    debug('%cA creation by Boite à Oeufs 🇫🇷', style)
    debug('%c> Site : https://www.boite-a-oeufs.com', style)
    debug('%c> Twitter : https://twitter.com/BoiteOeufs', style)
    debug('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)

    debug('%c> Design by Julian Camaret', style)
    debug('%c> Twitter : https://twitter.com/Jus_motion', style)

    debug('%cDevelopment by Romain Murschel', style)
    debug('%c> Site : https://romainmurschel.fr', style)
    debug('%c> Twitter : https://twitter.com/balthorg', style)
  },

}
window.app = app;

window.onload = (event) => {
  const $style = document.getElementById('bao-style-css');

  if ($style.isLoaded) {
    console.log('style is already loaded');
    app.init();
  } else {
    console.log('waiting for style');
    $style.addEventListener('load', (event) => {
      console.log('style is loaded');
      app.init();
    });
  }
};

// If the cache is loaded via bfcache (back/forward cache)
// https://web.dev/bfcache/
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    // console.log('This page was restored from the bfcache.');

    const $style = document.getElementById('bao-style-css');

    if ($style.isLoaded) {
      debug('style is already loaded');
      app.init();
    } else {
      console.log('waiting for style');
      $style.addEventListener('load', (event) => {
        debug('style is loaded');
        app.init();
      });
    }

  } else {
    // console.log('This page was loaded normally.');
  }
});

// window.onbeforeunload = function (e) {
//   // e = e || window.event;

//   // // For IE and Firefox prior to version 4
//   // if (e) {
//   //     e.returnValue = 'Sure?';
//   // }

//   // // For Safari
//   // return 'Sure?';
  
//   localStorage.clear();
// };



// function sleep(milliseconds) {
//   const date = Date.now();
//   let currentDate = null;
//   do {
//     currentDate = Date.now();
//   } while (currentDate - date < milliseconds);
// }
// sleep(5000);
