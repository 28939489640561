// import { isMobile } from '@utils/environment';
// import Attr from '@utils/attributes';
import { rafTimeout } from '@utils/RAF';
// import { scrollTo } from '@utils/scroll';

export class Page {
  constructor(name, app) {
    this.app = app;
    this.name = name;
    this.last = '';
    this.current = '';
    
    this.newAll();
    this.call('new' + this.name);
  }

  init() {
    // console.log('call init from Page', this.name);
    this.initAll();
    this.call('init' + this.name);
  }

  // When construct any Page
  newAll() {
    const _this = this;

    // this.current = this.DOM.main.querySelector('#page-content').dataset.routerView;
    this.last = localStorage.getItem('page.current');
    this.current = location.href;
    localStorage.setItem('page.current', this.current);

    // if (document.querySelectorAll('.js-projetslist').length && !isMobile) {
    //   import('@modules/projetslist').then(function (module) {
    //     _this.ProjetsList = new module.ProjetsList('.js-projetslist', _this.app);
    //     _this.app.addPageModule(_this.ProjetsList);
    //   });
    // }
  }
  initAll() {
  }

  // When construct specific Page...
  // => Home
  newHome() {
    const _this = this;

    import('@modules/teamlist').then(function (module) {
      _this.TeamList = new module.TeamList(_this.app);
      _this.app.addPageModule(_this.TeamList);
    });
  }
  initHome() {
    
    // Title words animation
    let title = document.querySelector('#home-intro .title-with-words');
    let words = title.dataset.words.split(',');
    let arr_words = [];

    // console.log('initHome');

    words.forEach( (word, i) => {
      const w = document.createElement('span');
      w.classList.add('word');
      w.innerHTML = word;
      title.appendChild(w);
      charming(w);
      let spans = [...w.querySelectorAll('span')];

      arr_words.push({
        'word': w,
        'spans': spans,
        'animIn': gsap.timeline().fromTo(
            spans,
            {
              opacity: 1,
              y: '-100%',
            },
            {
              opacity: 1,
              y: '0%',
              duration: 0.8,
              delay: .6,
              ease: "power3.out",
              stagger: 0.05,
            }
          ).pause(),
        'animOut': gsap.timeline().fromTo(
          spans,
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 1,
            y: '100%',
            duration: 0.8,
            ease: "power3.in",
            stagger: 0.05,
            onComplete: () => {
              gsap.set(spans, { opacity: 0 });
              current = neww;
              rafTimeout(words_timeout, 2000);
            }
          }
        ).pause(),
      });
      gsap.set(spans, { opacity: 0 });
    });

    let max = arr_words.length;
    let neww = -1;
    let current = 0;
    arr_words[current].animIn.restart(); // initial

    let words_timeout = () => {
      neww = current + 1;
      if (neww > max-1) neww = 0;
      arr_words[neww].animIn.restart();
      arr_words[current].animOut.restart();
    }
    rafTimeout(words_timeout, 2000); // loop


    // Definition word hover effect
    // if ( !isMobile ) {
    //   let definition_word = document.querySelector('.home-definition .definition_word');
    //   definition_word.addEventListener('mouseenter', (e) => {
    //     this.app.Cursor.showPony();
    //   });
    //   definition_word.addEventListener('mouseleave', (e) => {
    //     this.app.Cursor.hidePony();
    //   });
    // }
  }

  // newSingleProjets() {
  // }
  // initSingleProjets() {
  // }

  _onPageReady() {
    // if (this.current === this.last) {
    //   // window.scrollTo(0, this.initScrollPosition);
    //   scrollTo(0, this.app.initScrollPosition);
    // }

    this.call('onPageReady' + this.name);
  }

  call(func) {
    if (typeof this[func] === 'function') {
      this[func]();
    }
  }

}
