/*
  Request Animations Frame helpers

  Usage :
  interval1 = window.rafInterval( function() { console.log('interval1') }, 2000 );
  timeout1 = window.rafTimeout( function() { console.log('timeout1') }, 5000 );

  Clear :
  interval1.clear();
  timeout1.clear();
*/

export function rafTimeout ( callback, delay ) {
 var  dateNow = Date.now,
      // requestAnimation = window.requestAnimationFrame,
      requestAnimation = (function () {
        return window.requestAnimationFrame || function (callback, element) {
          window.setTimeout(callback, 1000 / 60);
        };
      })(),
      start = new Date().getTime(),
      stop,
      timeoutFunc = function () {
        dateNow() - start < delay ? stop || requestAnimation(timeoutFunc) : callback()
      };

 requestAnimation(timeoutFunc);

 return {
  clear: function () { stop = 1 }
 }
}

export function rafInterval ( callback, delay ) {
 var  dateNow = Date.now,
      // requestAnimation = window .requestAnimationFrame,
      requestAnimation = (function () {
        return window.requestAnimationFrame || function (callback, element) {
          window.setTimeout(callback, 1000 / 60);
        };
      })(),
      start = dateNow(),
      stop,
      intervalFunc = function () {
        dateNow() - start < delay || (start += delay, callback() );
        stop || requestAnimation(intervalFunc)
      };

 requestAnimation(intervalFunc);

 return {
  clear: function () { stop = 1 }
 }
}