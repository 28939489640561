import Attr from '@utils/attributes';

const SELECTORS = {
  cookieNotice: 'cookie-notice',
  cnContainer : '.cookie-notice-container',
  cnAccept    : 'cn-accept-cookie'
}

export class Cookie {
  constructor(app) {
    this.app = app;

    this.cookieNotice = document.getElementById(SELECTORS.cookieNotice);
    this.cookie_img = '<svg><use xlink:href="#icon-cookie"></use></svg>';

    if (this.cookieNotice) {
      this.cnContainer = document.querySelector(SELECTORS.cnContainer, this.cookieNotice);
      this.cnAccept    = document.getElementById(SELECTORS.cnAccept);
      this.cnContainer.querySelector('.cookie-notice-img').innerHTML = this.cookie_img;

      if ( Attr.hasClass(this.app.DOM.body, 'cookies-accepted') ) {
        window.addEventListener('load', (e) => {
          this.cookieNotice.remove();
        });
      }
    }
  }

  init() {
    if (!this.cookieNotice) return;

    // document.addEventListener('setCookieNotice', (e) => {
    //   console.log('cookie notice set');
    // });

    this.cnAccept.addEventListener('click', (e) => {
      gsap.timeline({
        onStart: () => {
          this.cookieNotice.style.transformOrigin = 'right';
          this.cookieNotice.style.transitionDelay = '0s';
        },
        onComplete: () => {
          this.cookieNotice.remove();
        }
      })
      .to(this.cnContainer, .4, { opacity: 0 })
      .to(this.cookieNotice, .4, {
        // opacity: 0,
        scaleX: 0,
        delay: .2
      });
    });
  }
}