import Snif from '@utils/snif';

const APP_NAME     = 'Boite à Oeufs v4';
const DATA_API_KEY = '.data-api';
const GA_CODE      = 'G-931Q9YXE95';

const html         = document.documentElement;
const body         = document.body;

const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};

const isIE         = Snif.isIE;
const isMobile     = Snif.isMobile;
const isDebug      = html.hasAttribute('data-debug');
const isAdmin      = body.classList.contains('wp-admin');

const cssVariablesSupport = window.CSS && CSS.supports('color', 'var(--fake-var)');
const cssMixBlendMode     = window.CSS && CSS.supports('mix-blend-mode','multiply');

// const webpSupport              = Util.hasClass( document.documentElement, 'webp' );
const intersectionObserverSupport = "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype;
const mutationObserverSupport     = "MutationObserver" in window;
const idleCallbackSupport         = "requestIdleCallback" in window;
const reducedMotion               = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

export {
  APP_NAME, DATA_API_KEY, GA_CODE,

  html, body,

  connection,

  isIE, isMobile, isDebug, isAdmin,

  cssVariablesSupport, cssMixBlendMode, intersectionObserverSupport, mutationObserverSupport, idleCallbackSupport, reducedMotion
};